<template>
  <LandingpageCard
    :name="fullName"
    :icon="icon"
    has-action
  >
    <template v-slot:action>
      <SnippetModal
        :landingpage-slug="`liste-${name.toLowerCase()}`"
        landingpage-type="LIST"
        :readable-landingpage="fullName"
        :company-slug="companySlug"
        :company-site-url="companySiteUrl"
      />
    </template>
    <template v-slot:content>
      <div class="design-wrapper hidden-sm-and-down">
        <Leaf
          class="design-elements"
          width="200"
          style="top:-100px; right: -20px; opacity: 0.5"
          fill
        />
      </div>
      <div class="d-flex content">
        <span>
          Sie wollen mit nur einer Einbindung alle {{ name }} anbieten?<br>
          Kein Problem. Mit dem <b>{{ fullName }}</b> Widget gelingt das in wenigen Schritten.
          <span class="hidden-sm-and-down">
            Kopieren Sie einfach den Einbindungs-Code an die passende Stelle in Ihrer Webseite und schon sind alle {{ name }} für die Besucher Ihrer Seite sichtbar.
          </span>
        </span>
      </div>
    </template>
  </LandingpageCard>
</template>

<script>
import LandingpageCard from '../LandingpageCard.vue'
import Leaf from '@/components/design-elements/leaf.vue'
import SnippetModal from '../snippet/index.vue'

export default {
  components: { SnippetModal, LandingpageCard, Leaf },

  props: {
    companySlug: { type: String, default: '' },
    companySiteUrl: { type: String, default: '' },
    name: { type: String, default: '' },
    icon: { type: String, default: '' }
  },
  computed: {
    fullName () {
      return `Alle ${this.name} in Einem`
    }
  }
}
</script>

<style scoped>
.design-wrapper {
  position: relative;
  height: 1px;
}
.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.content{
  z-index: 2;
  position: relative
}
</style>
