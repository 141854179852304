<template>
  <div>
    <div v-if="isLight">
      <UpgradeCard
        v-if="type === 'GUIDE'"
        cta="Ja, ich will alle Ratgeber!"
        title="Ratgeber: stark für die Akquise – und Ihre Marke!"
      >
        <v-col
          lg="10"
          cols="12"
        >
          <div class="text-body-1">
            BOTTIMMO Premium-Kunden können hier aus einer Fülle an
            Ratgebern auswählen. Ratgeber sind ein idealer Köder für die
            Akquise. Zudem untermauern Sie Ihre Expertise.
          </div>
          <div class="my-4 upgrade-list">
            <div class="mb-2 text-body-1 font-weight-bold">
              Werden auch Sie im Handumdrehen zum Autor folgender Ratgeber:
            </div>
            <UpgradeItemList
              :items="bookableLps.map(lp => lp.name)"
            />
          </div>
          <div class="mb-1 text-body-1">
            Jeder Ratgeber wird in Ihren Unternehmensfarben und mit Ihrem
            Logo erstellt und mit Ihrem Bild und das des Teams ergänzt.
            Das ganze gibt es zum Download auf Ihrer Landingpage, zum
            Einbau in Ihre Website sowie als hochwertige Printversion.
          </div>
          <div class="text-body-1">
            Ist in Ihrer Farm noch ein Premium-Paket verfügbar? Das finden
            wir im persönlichen Gespräch heraus.
          </div>
        </v-col>
      </UpgradeCard>
      <UpgradeCard
        v-if="type === 'CHECKLIST'"
        cta="Ja, ich will die Checklisten!"
        title="Checklisten: der besondere Service für Ihre Kunden"
      >
        <v-col
          lg="10"
          cols="12"
        >
          <div class="text-body-1">
            BOTTIMMO Premium-Kunden können hier aus diversen Checklisten auswählen.
            Checklisten sind ein guter Köder und ein starker Service für Ihre Kunden.
          </div>
          <div class="my-4 upgrade-list">
            <div class="mb-2 text-body-1 font-weight-bold">
              Folgende Checklisten stehen zur Verfügung:
            </div>
            <UpgradeItemList :items="bookableLps.map(lp => lp.name)" />
          </div>
          <div class="mb-1 text-body-1">
            Jede Checkliste wird in Ihren Unternehmensfarben und mit Ihrem Logo erstellt.
            Das ganze gibt es zum Download auf Ihrer Landingpage, zum Einbau in Ihre Website sowie als Printversion.
          </div>
          <div class="text-body-1">
            Ist in Ihrer Farm noch ein Premium-Paket verfügbar? Das finden wir im persönlichen Gespräch heraus.
          </div>
        </v-col>
      </UpgradeCard>
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <AllInOneCard
            :company-slug="company.slug"
            :company-site-url="company.site.url"
            :name="readableType"
            :icon="icon"
          />
        </v-col>
      </v-row>
      <h3 class="my-4 overline grey--text text--darken-1">
        Einzelne {{ readableType }}
      </h3>
      <v-row
        v-for="lp in landingpages"
        :key="lp.slug"
      >
        <v-col>
          <LandingpageCard
            v-if="hasReadyMaterials(lp.id)"
            v-bind="lp"
            has-action
          >
            <template v-slot:content>
              <LandingpageCardContent
                v-bind="lp"
                :landingpage-id="lp.id"
                conversion-label="angefordert"
                :materials-length="materialCount(lp.id)"
              />
            </template>
          </LandingpageCard>

          <UpsellingCard
            v-else
            :name="lp.name"
            :slug="lp.slug"
            :type="lp.type"
            :running="true"
            :is-booking-blocked="false"
          />
        </v-col>
      </v-row>
      <v-row
        v-for="lp in bookableLps"
        :key="lp.slug"
      >
        <v-col>
          <UpsellingCard
            :name="lp.name"
            :type="lp.type"
            :slug="lp.slug"
            :is-booking-blocked="isBookingBlocked"
            :submit="bookLandingpage"
            :price="price"
            :variant="company.variant"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { isLightUser } from '@/lib/productTypes'
import LandingpageCard from '../LandingpageCard'
import LandingpageCardContent from '../LandingpageCardContent'
import UpsellingCard from '../../upselling/UpsellingCard.vue'
import AllInOneCard from './AllInOneCard.vue'
import materialCount from '@/lib/materialCount'
import UpgradeCard from '../UpgradeCard.vue'
import UpgradeItemList from '../UpgradeItemList.vue'

const ALL_IN_ONE_TYPE_MAP = {
  GUIDE: {
    name: 'Ratgeber',
    icon: 'mdi-book-open-variant'
  },
  CHECKLIST: {
    name: 'Checklisten',
    icon: 'mdi-clipboard-text'
  }
}
export default {
  components: {
    LandingpageCard,
    LandingpageCardContent,
    UpsellingCard,
    AllInOneCard,
    UpgradeCard,
    UpgradeItemList
  },

  props: {
    type: {
      type: String,
      default: ''
    },
    getMaterials: {
      type: Function,
      required: true
    },
    bookLandingpage: {
      type: Function,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    landingpages: {
      type: Array,
      required: true
    },
    bookableLps: {
      type: Array,
      required: true
    },
    price: {
      type: Object,
      default: () => ({})
    },
    isBookingBlocked: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isLight () {
      return isLightUser(this.$auth.user)
    },
    readableType () {
      return ALL_IN_ONE_TYPE_MAP[this.type].name
    },
    icon () {
      return ALL_IN_ONE_TYPE_MAP[this.type].icon
    }
  },

  methods: {
    hasReadyMaterials (lpId) {
      const materials = this.getMaterials(lpId)
      return materials.length > 0 && materials.every(material => material.hasFile)
    },
    materialCount (lpId) {
      const materials = this.getMaterials(lpId)
      return materialCount(materials)
    }
  }

}
</script>

<style scoped>
.design-wrapper {
  position: relative;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}
</style>
