var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.landingpages),function(lp){return _c('v-row',{key:lp.slug},[_c('v-col',[_c('LandingpageCard',_vm._b({attrs:{"icon":lp.icon,"has-action":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('LandingpageCardContent',_vm._b({attrs:{"landingpage-id":lp.id,"conversion-label":_vm.conversionLabel(lp.type),"materials-length":_vm.isLight ? _vm.lightMaterials[lp.slug].length : _vm.materialCount(lp.id),"has-click-action":""},on:{"click":function($event){return _vm.handleClick(lp.type)}}},'LandingpageCardContent',lp,false))]},proxy:true}],null,true)},'LandingpageCard',lp,false))],1)],1)}),(_vm.permissions && _vm.permissions.leadImport.hasPermission)?_c('v-row',[_c('v-col',[_c('LandingpageCard',{attrs:{"name":"Eigentums-Radar","icon":"mdi-radar","slug":"eigentums-radar"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('LandingpageStatisticsCardContent',{attrs:{"statistics":_vm.propertyTrackerStatistics}})]},proxy:true}],null,false,1285076350)})],1)],1):_vm._e(),(_vm.isLight)?_c('UpgradeCard',{attrs:{"cta":"Ja, ich will alle Tools!","title":"Sie wollen mehr kleine Helfer? Mehr Landingpages? Mehr Werbemittel? Bitte schön!"}},[_c('v-col',{attrs:{"lg":"10","cols":"12"}},[_c('div',{staticClass:"text-body-1"},[_vm._v(" Für den vollen Akquise-Schub profitieren BOTTIMMO Premium-Kunden von einem umfangreichen Programm an Werkzeugen und Landingpages: ")]),_c('div',{staticClass:"my-4 upgrade-list"},[_c('UpgradeItemList',{attrs:{"items":[
            'Immobilienbewertung für Ihre Kunden mit Sofortergebnis',
            'In-App Immobilienbewertung für Sie',
            'Grundstücksbewertung',
            'Leibrenten-Check',
            'Situations-Analyse'
          ]}})],1),_c('div',{staticClass:"mb-1 text-body-1"},[_vm._v(" Zu allen Tools finden Sie zudem eine Auswahl an wirksamen Werbemitteln wie Flyern, Plakaten, Videos und Social Media Posts. ")]),_c('div',{staticClass:"text-body-1"},[_vm._v(" Ist in Ihrer Farm noch ein Premium-Paket verfügbar? Das finden wir im persönlichen Gespräch heraus. ")])])],1):_c('div',_vm._l((_vm.bookableLps),function(lp){return _c('v-row',{key:lp.slug},[_c('v-col',[_c('UpsellingCard',{attrs:{"name":lp.name,"type":lp.type,"slug":lp.slug,"is-booking-blocked":_vm.isBookingBlocked,"submit":_vm.bookLandingpage,"price":_vm.price,"variant":_vm.company.variant}})],1)],1)}),1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }