const filterOnlyFiles = material => material.hasFile

function materialCount (materials) {
  let count = coverFiles(materials).length
  basicAds(materials).forEach(basicAd => { count += basicAd.files.length })
  downloads(materials).forEach(download => { count += download.files.length })
  return count
}

function coverFiles (materials) {
  const material = materials.find(material => (
    material.type === 'DOWNLOAD' &&
    material.purpose === 'SCREEN'
  ))
  if (material && material.files) {
    return material.files
  }
  return []
}

function downloads (materials) {
  return materials
    .filter(filterOnlyFiles)
    .filter(material => material.type === 'DOWNLOAD')
}

function basicAds (materials) {
  return materials
    .filter(filterOnlyFiles)
    .filter(material => material.type === 'BASIC_AD')
}

export default materialCount
