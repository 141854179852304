<template>
  <div class="d-flex">
    <div
      v-for="(item,index) in statistics"
      :key="index"
      class="d-flex align-center"
    >
      <v-icon class="mr-2">
        {{ item.icon }}
      </v-icon>
      <span>
        <span class="text-h5">{{ item.value }}</span> x {{ item.label }}
      </span>
      <v-divider
        v-if="!isLastElement(index)"
        vertical
        class="mx-2"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statistics: { type: Array, default: () => [] }
  },
  methods: {
    isLastElement (index) {
      return index >= (this.statistics.length - 1)
    }
  }
}
</script>
