import createClient from '@/clients/api'
import { appBranding } from '@/lib/features'

export const downloadMaterials = async (path, filename) => {
  const data = await fetchData(path)
  const date = new Date().toISOString().substring(0, 10)
  const companyName = (appBranding?.isActive && appBranding?.config?.readablePlatformName) ? appBranding.config.readablePlatformName : ''

  triggerDownload({
    filename: `${date}_${companyName}_${filename}.zip`,
    data
  })
}

const fetchData = async url => {
  const client = await createClient()
  const res = await client.get(url)

  return res.data
}

const triggerDownload = ({ filename, data }) => {
  if (data) {
    const blob = window.URL.createObjectURL(new Blob([data]))
    const element = document.createElement('a')
    element.href = blob
    element.setAttribute('download', filename)
    document.body.appendChild(element)

    element.click()
    element.remove()
  }
}
