<template>
  <div>
    <div v-if="campaigns">
      <v-row
        v-for="campaign in campaigns"
        :key="campaign.id"
      >
        <v-col>
          <StaticCard
            :campaign="campaign"
            :materials="getMaterials(campaign.id)"
            :country="company.country"
          />
        </v-col>
      </v-row>
    </div>
    <UpgradeCard
      v-if="isLight"
      :icon-spacing="100"
      cta="Ja, ich will die Vorlagen!"
      title="Vorlagen: Hilfreiches für die Marketing-Praxis"
    >
      <v-col
        lg="10"
        cols="12"
      >
        <div class="text-body-1">
          BOTTIMMO Premium-Kunden können hier aus hilfreichen Vorlagen auswählen. Eine solche Vorlage gibt Ihnen einen
          professionellen Text an die Hand, den Sie nach wenigen Anpassungen sofort verwenden können.
        </div>
        <div class="my-4 upgrade-list">
          <div class="mb-2 text-body-1 font-weight-bold">
            Vorlagen sind zum Beispiel:
          </div>
          <UpgradeItemList
            :items="['Interview-Vorlagen','PR-Artikel','Gesprächsleitfäden','Kundenanschreiben']"
          />
        </div>
        <div class="text-body-1">
          Ist in Ihrer Farm noch ein Premium-Paket verfügbar? Das finden wir im persönlichen Gespräch heraus.
        </div>
      </v-col>
    </UpgradeCard>
  </div>
</template>

<script>
import StaticCard from './StaticCard.vue'
import UpgradeCard from '../UpgradeCard.vue'
import UpgradeItemList from '../UpgradeItemList.vue'
import { isLightUser } from '@/lib/productTypes'

export default {
  components: {
    StaticCard,
    UpgradeCard,
    UpgradeItemList
  },

  props: {
    getMaterials: {
      type: Function,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    campaigns: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    isLight () {
      return isLightUser(this.$auth.user)
    }
  }
}
</script>
