<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <Blocked
        v-if="isBlocked"
        key="banner"
        :type="type"
        :price="formatedPrice"
        :submit="goToConfirm"
      />
      <Banner
        v-if="isIdle"
        key="banner"
        :type="type"
        :price="formatedPrice"
        :submit="goToConfirm"
        :slug="slug"
        :variant="variant"
      />
      <Confirmation
        v-if="isConfirming"
        key="confirmation"
        :type="type"
        :price="formatedPrice"
        :cancel="goToIdle"
        :submit="submitBooking"
        :requirements="requirements"
      />
      <InProgress
        v-if="isRunning"
        key="inProgress"
        :slug="slug"
      />
    </transition>
  </div>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import Blocked from './Blocked'
import Banner from './Banner'
import Confirmation from './Confirmation'
import UpsellingType from './enums/UpsellingType'
import bus, { eventNames } from '@/lib/eventBus'

const StatusType = {
  IDLE: 'IDLE',
  CONFIRM: 'CONFIRM',
  RUNNING: 'RUNNING',
  BLOCKED: 'BLOCKED'
}

export default {
  components: {
    Banner,
    Confirmation,
    InProgress: () => import('./InProgress'),
    Blocked
  },

  mixins: [currencyMixin],

  props: {
    type: { type: String, default: UpsellingType.CHECKLIST },
    isBookingBlocked: { type: Boolean, default: true },
    slug: { type: String, default: '' },
    completed: { type: Function, default: () => {} },
    requirements: { type: Array, default: () => [] },
    running: { type: Boolean, default: false },
    submit: { type: Function, default: () => {} },
    price: { type: Object, default: () => {} },
    variant: { type: String, default: '' }
  },

  data () {
    return {
      status: StatusType.IDLE
    }
  },

  computed: {
    isBlocked () {
      return this.status === StatusType.BLOCKED
    },
    isIdle () {
      return this.status === StatusType.IDLE
    },
    isConfirming () {
      return this.status === StatusType.CONFIRM
    },
    isRunning () {
      return this.status === StatusType.RUNNING
    },
    formatedPrice () {
      if (!this.price || !this.price.value) return
      return this.formatPrice(this.price.value, this.price.currency)
    }
  },

  mounted () {
    if (this.isBookingBlocked) {
      this.status = StatusType.BLOCKED
    } else if (this.running) {
      this.status = StatusType.RUNNING
    }
  },

  methods: {
    async submitBooking () {
      this.status = StatusType.RUNNING
      try {
        await this.submit(this.type, this.slug)
        bus.$emit(eventNames.TRIGGER_ANIMATION)
      } catch (error) {
        this.status = StatusType.IDLE
        bus.$emit(eventNames.SHOW_SNACKBAR,
          {
            color: 'error',
            text: 'Problem beim Buchen, bitte versuchen Sie es später nochmal oder kontaktieren Sie unseren Support',
            time: 10000
          })
      }
    },

    goToIdle () {
      this.status = StatusType.IDLE
    },
    goToConfirm () {
      this.status = StatusType.CONFIRM
    }
  }

}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}
</style>
