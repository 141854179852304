import createClient from '@/clients/api'

export const exportValuation = async ({ id }) => {
  const data = await fetchData(`/valuations/${id}/openImmo`)
  triggerDownload({ filename: 'export.xml', data })
}

export const exportPlotValuation = async ({ id }) => {
  const data = await fetchData(`/plot-valuation/${id}/export`)
  triggerDownload({ filename: 'export.xml', data })
}
export const exportImplementationData = async companyId => {
  const url = `/implementation-data/${companyId}/export`
  const data = await fetchData(url)
  triggerDownload({ filename: 'Implementierungsdaten.csv', data })
}

export const exportLead = async ({ id }) => {
  const data = await fetchData(`/leads/${id}/export`)
  triggerDownload({ filename: 'export.csv', data })
}

export const exportLeads = async ({ sortBy, sortDesc, search, filters, onlyNotExported = false }) => {
  const data = await fetchData('/leads/export', {
    sortBy,
    sortDesc,
    search,
    filters,
    onlyNotExported
  })
  triggerDownload({ filename: 'export.csv', data })
}

const fetchData = async (url, params) => {
  const client = await createClient()
  const res = await client.get(url, { params })

  return res.data
}

const triggerDownload = ({ filename, data }) => {
  if (data) {
    const blob = window.URL.createObjectURL(new Blob([data]))
    const element = document.createElement('a')
    element.href = blob
    element.setAttribute('download', filename)
    document.body.appendChild(element)

    element.click()
    element.remove()
  }
}
