<template>
  <div>
    <BaseHeader
      title="Landingpages &amp; Köder"
      :style-variant="2"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          Um Ihnen die Arbeit mit den Materialien zu erleichtern, haben wir Ihnen Pakete
          {{ isFeatureActive(featureNames.CONTRACT) ? ' und einen Leitfaden ' : '' }}
          zum Herunterladen zusammengestellt.
        </p>
      </template>
      <template v-slot:content>
        <div class="pb-2">
          <v-btn
            v-if="landingpageOverviewUrl"
            class="ml-0"
            text
            color="primary"
          >
            <a
              class="info-link"
              target="_blank"
              rel="noopener noreferrer"
              :href="landingpageOverviewUrl"
            >Landingpage Übersicht</a>
            <v-icon right>
              mdi-view-grid-outline
            </v-icon>
          </v-btn>

          <Feature
            v-slot="{feature}"
            :feature-slug="featureNames.MATERIAL_GUIDE"
          >
            <v-btn
              class="ml-0"
              text
              color="primary"
            >
              <a
                class="info-link"
                target="_blank"
                rel="noopener noreferrer"
                :href="feature.config.guideUrl"
              >Implementierungsleitfaden</a>
              <v-icon right>
                mdi-link
              </v-icon>
            </v-btn>
          </Feature>

          <v-btn
            class="ml-0"
            color="primary"
            text
            @click="downloadImplementationData"
          >
            Daten zur Implementierung
            <v-icon right>
              mdi-download
            </v-icon>
          </v-btn>

          <Download
            v-if="!isLight"
            text
            title="Alle Materialien"
            path="/materials/all"
            filename="Materialien"
            :tracking-event="trackingEvents.MATERIAL_DOWNLOADED_ALL"
          />
        </div>
      </template>
    </BaseHeader>
  </div>
</template>

<script>
import { exportImplementationData } from '@/lib/export'
import Download from './Download.vue'
import trackingEvents from '@/lib/trackingEvents'
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'
import BaseHeader from '@/components/BaseHeader.vue'
import featureMixin from '@/mixins/feature'
import LANDINGPAGE_OVERVIEW from './LandingpageOverview.gql'
import HeaderDesign from './HeaderDesign.vue'
import { isLightUser } from '@/lib/productTypes'

export default {
  components: { Download, Feature, BaseHeader, HeaderDesign },
  mixins: [featureMixin],
  data () {
    return {
      trackingEvents,
      featureNames
    }
  },
  computed: {
    landingpageOverviewUrl () {
      if (isLightUser(this.$auth.user)) return ''
      if (!this.company?.site) return ''
      return this.company?.site.url
    },
    isLight () {
      return isLightUser(this.$auth.user)
    }
  },
  methods: {
    async downloadImplementationData () {
      await exportImplementationData(this.$auth.user.companyId)
    }
  },
  apollo: {
    company: {
      query: LANDINGPAGE_OVERVIEW,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>

<style scoped>
.info-link{
  text-decoration: none;
}
</style>
