<template>
  <div class="d-flex">
    <span
      v-if="hasClickAction"
      style="cursor:pointer"
      class="primary--text"
      @click="handleClick"
    >
      <span
        class="text-h5 "
      >
        {{ conversionCount }}
      </span>

      x {{ conversionLabel }}
    </span>
    <span v-else>
      <span class="text-h5">{{ conversionCount }}</span> x {{ conversionLabel }}
    </span>
    <v-divider
      vertical
      class="mx-2"
    />
    <span
      style="cursor:pointer"
      class="primary--text"
      @click="gotToMaterials"
    >
      <span class="text-h5">{{ materialsLength }} </span> Materialien </span>
  </div>
</template>

<script>
export default {
  props: {
    conversionLabel: { type: String, default: '' },
    conversionCount: { type: Number, default: 0 },
    materialsLength: { type: Number, default: 0 },
    hasClickAction: { type: Boolean, default: false },
    landingpageId: { type: String, default: '' }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    },
    gotToMaterials () {
      this.$router.push(`/landingpages/${this.landingpageId}`)
    }
  }
}
</script>
