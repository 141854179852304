<template>
  <div v-if="company && materials && pricesForCompany && availableCampaigns">
    <v-card
      outlined
      class="px-2 pt-2 px-md-4"
    >
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          replace
          to="#tools"
        >
          Tools ({{ count(tools, 'tools') }})
        </v-tab>
        <v-tab
          replace
          to="#guides"
        >
          Ratgeber ({{ count(guides, 'guides') }})
        </v-tab>
        <v-tab
          replace
          to="#checklists"
        >
          Checklisten ({{ count(checklists, 'checklists') }})
        </v-tab>
        <v-tab
          replace
          to="#campaigns"
        >
          Kampagnen ({{ bookedCampaigns.length }} / {{ realCampaigns.length }})
        </v-tab>
        <v-tab
          v-if="showTemplateTab"
          replace
          to="#templates"
        >
          Vorlagen ({{ staticCampaigns.length }})
        </v-tab>
      </v-tabs>

      <v-divider />

      <v-tabs-items
        v-model="tab"
        class="py-4 transparent"
      >
        <v-tab-item value="tools">
          <ToolsTab
            :company="company"
            :landingpages="tools"
            :get-materials="getNonCampaignMaterialsForLandinpage"
            :bookable-lps="bookableLps('tools')"
            :book-landingpage="bookLandingpage"
            :is-booking-blocked="isBookingBlocked"
            :price="price('landingpage')"
          />
        </v-tab-item>

        <v-tab-item value="guides">
          <DownloadTab
            type="GUIDE"
            :company="company"
            :price="price('landingpage')"
            :landingpages="guides"
            :bookable-lps="bookableLps('guides')"
            :is-booking-blocked="isBookingBlocked"
            :get-materials="getNonCampaignMaterialsForLandinpage"
            :book-landingpage="bookLandingpage"
          />
        </v-tab-item>

        <v-tab-item value="checklists">
          <DownloadTab
            type="CHECKLIST"
            :company="company"
            :price="price('checklist')"
            :landingpages="checklists"
            :bookable-lps="bookableLps('checklists')"
            :is-booking-blocked="isBookingBlocked"
            :get-materials="getNonCampaignMaterialsForLandinpage"
            :book-landingpage="bookLandingpage"
          />
        </v-tab-item>

        <v-tab-item value="campaigns">
          <CampaignTab
            :price="price('campaign')"
            :company="company"
            :campaigns="realCampaigns"
            :booked-campaigns="bookedCampaigns"
            :landingpages="landingpages"
            :landingpage-templates="landingpageTemplates"
            :is-booking-blocked="isBookingBlocked"
            :get-materials="getCampaignMaterials"
          />
        </v-tab-item>
        <v-tab-item
          v-if="showTemplateTab"
          value="templates"
        >
          <StaticTab
            :company="company"
            :campaigns="staticCampaigns"
            :get-materials="getCampaignMaterials"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import LANDINGPAGE_TEMPLATES_FOR_COMPANY from '@/queries/LandingpageTemplatesForCompany.gql'
import PRICES from '../../queries/PricesForCompany.gql'
import LANDINGPAGES from './Landingpages.gql'
import AVAILABLE_CAMPAIGNS from './AvailableCampaigns.gql'
import MATERIALS from './materials.gql'
import BOOK_LANDINGPAGE from './BookLandingpage.gql'
import BOOKED_CAMPAIGNS from '@/queries/BookedCampaigns.gql'
import { TOOLS } from '@/lib/tools'
import { isExpertUser } from '@/lib/productTypes'
import ToolsTab from './tools/ToolsTab.vue'
import DownloadTab from './download/DownloadTab.vue'
import CampaignTab from './campaigns/CampaignTab.vue'
import StaticTab from './static/StaticTab.vue'
import featureNames from '@/lib/featureNames'
import brandingMixin from '@/mixins/branding'

const LP_TYPE_MAP = {
  tools: ['VALUATION', 'RETURN_CALL', 'LIFE_ANNUITY', 'PARTIAL_SALE', 'PLOT_VALUATION', 'QUIZZARD', 'PROPERTY_REQUEST'],
  guides: ['GUIDE'],
  checklists: ['CHECKLIST']
}

export default {
  components: {
    ToolsTab,
    DownloadTab,
    CampaignTab,
    StaticTab
  },
  mixins: [brandingMixin],

  data: () => ({
    company: null,
    materials: [],
    landingpageTemplates: [],
    tab: 'tools',
    fetchLandingpageInterval: null,
    fetchLandingpageTimeout: null
  }),

  apollo: {
    company: {
      query: LANDINGPAGES,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    },
    pricesForCompany: {
      query: PRICES,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            readableIds: this.availableProductReadableIds
          }
        }
      }
    },
    materials: {
      query: MATERIALS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    landingpageTemplates: {
      query: LANDINGPAGE_TEMPLATES_FOR_COMPANY,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            onlyDefault: true
          }
        }
      }
    },
    availableCampaigns: {
      query: AVAILABLE_CAMPAIGNS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    bookedCampaigns: {
      query: BOOKED_CAMPAIGNS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  },

  computed: {
    availableProductReadableIds () {
      const readableIds = ['landingpage', 'checklist']
      readableIds.push(this.isExpert ? 'campaign-expert' : 'campaign-basic')
      return readableIds
    },

    isExpert () {
      return isExpertUser(this.$auth.user)
    },

    landingpages () {
      return this.company.landingpages.filter(({ slug }) =>
        this.$features.feature(`lp-${slug}`).isActive
      )
    },

    isBookingBlocked () {
      if (!this.$features.feature(featureNames.CONTRACT).isActive) {
        return false
      }
      const currentDate = new Date()
      if (!this.company.currentContract.endDate) {
        return false
      }
      const endDate = new Date(this.company.currentContract.endDate)
      const isInNext2Months = Math.floor(Math.abs(endDate - currentDate) / 86400000) < 60
      return (this.company.state.value.ACTIVE === 'CANCELLED' && isInNext2Months)
    },

    showTemplateTab () {
      return this.$features.feature(featureNames.STATIC_CAMPAIGN).isActive
    },

    tools () {
      return this.filterType(this.landingpages, LP_TYPE_MAP.tools)
        .map(lp => ({ ...lp, ...TOOLS.find(tool => tool.type === lp.type) }))
        .filter(lp => lp.order)
        .sort((a, b) => a.order - b.order)
    },
    guides () {
      return this.filterType(this.landingpages, LP_TYPE_MAP.guides)
    },
    checklists () {
      return this.filterType(this.landingpages, LP_TYPE_MAP.checklists)
    },
    mutliScaleCampaigns () {
      return this.availableCampaigns.filter(campaign => campaign.slug.startsWith('ms-'))
    },
    realCampaigns () {
      return this.mutliScaleCampaigns.filter(campaign => !campaign.isStatic)
    },
    staticCampaigns () {
      return this.mutliScaleCampaigns.filter(campaign => campaign.isStatic)
    },

    toolTemplates () {
      return this.filterType(this.landingpageTemplates, LP_TYPE_MAP.tools)
    },
    guideTemplates () {
      return this.filterType(this.landingpageTemplates, LP_TYPE_MAP.guides)
    },
    checklistTemplates () {
      return this.filterType(this.landingpageTemplates, LP_TYPE_MAP.checklists)
    }
  },

  unmounted () {
    if (this.fetchLandingpageInterval) {
      clearInterval(this.fetchLandingpageInterval)
    }
    if (this.fetchLandingpageTimeout) {
      clearTimeout(this.fetchLandingpageTimeout)
    }
  },

  methods: {
    isCampaignAvailableForCountry ({ landingpages }) {
      return landingpages.length === 0 || landingpages.some(({ country }) => country === this.company?.country)
    },

    count (template, type) {
      const activeCount = template?.length || 0
      const bookableCount = this.bookableLps(type)?.length || 0
      return `${activeCount}/${activeCount + bookableCount}`
    },

    bookableLps (type) {
      return this.filterType(this.landingpageTemplates, LP_TYPE_MAP[type]).filter(lp => this.isUpsellingEnabled(lp)).filter((lp) => !this.isLpExisting(lp))
    },

    isLpExisting ({ slug }) {
      return this.landingpages.find(lp => lp.slug === slug)
    },

    isUpsellingEnabled (lp) {
      return this.$features.feature(`lp-${lp.slug}`).isUpsellingEnabled
    },

    filterType (items, types) {
      return items.filter(lp => types.includes(lp.type))
    },

    price (type) {
      return this.pricesForCompany.find(price => price.readableId.includes(type))
    },

    getNonCampaignMaterialsForLandinpage (lpId) {
      return this.materials.filter(({ landingpageId, type }) => landingpageId === lpId && type !== 'CAMPAIGN')
    },

    getCampaignMaterials (campaignId) {
      return this.materials.filter((material) => material.campaignId === campaignId)
    },

    startFetchLandingpageInterval () {
      // for 15 minutes fetch all LPs every minute
      const timeout = 1000 * 60 * 15
      this.fetchLandingpageInterval = setInterval(() => {
        this.$apollo.queries.company.refetch()
        this.$apollo.queries.landingpageTemplates.refetch()
        this.$apollo.queries.materials.refetch()
      }, 60000)

      this.fetchLandingpageTimeout = setTimeout(() => {
        clearInterval(this.fetchLandingpageInterval)
      }, timeout)
    },

    async bookLandingpage (type, slug) {
      try {
        await this.$apollo.mutate({
          mutation: BOOK_LANDINGPAGE,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              slug: slug,
              type
            }
          }
        })
        this.startFetchLandingpageInterval()
      } catch (e) {
        throw new Error('GraphQL Error: ', e)
      }
    }
  }

}
</script>
