<template>
  <v-card
    outlined
    class="d-flex flex-column my-4"
  >
    <v-card-title class="d-flex">
      <h4 class="font-weight-regular">
        {{ title }}
      </h4>
    </v-card-title>
    <v-divider />
    <v-card-text class="overflow-hidden">
      <div class="design-wrapper">
        <Leaf
          class="design-elements"
          width="500"
          style="top:-650px; left: -150px; opacity: 0.15"
          fill
        />

        <Leaf
          class="design-elements"
          width="60"
          :style="`top:${iconSpacing}px; left: 10; opacity: 0.8`"
          stroke
          :stroke-width="1"
        />

        <Leaf
          class="design-elements"
          width="60"
          :style="`top:${iconSpacing}px; left:20; opacity: 0.3`"
          fill
        />
      </div>

      <slot />

      <div class="ma-4 mx-8 d-flex">
        <v-spacer />
        <a
          target="_blank"
          href="https://calendly.com/bottimmo-marketing-experten/marketing-beratung?utm_source=upgrade-cta&utm_medium=app&utm_campaign=sales"
        >
          <v-btn color="primary">
            {{ cta }}
          </v-btn>
        </a>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'

export default {
  components: {
    Leaf
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    cta: {
      type: String,
      required: true
    },
    iconSpacing: {
      type: Number,
      default: 80
    }
  }
}
</script>

<style>
@media (min-width: 960px) {
  .upgrade-list {
    margin-left: 120px;
  }
}
</style>

<style scoped>
.design-wrapper {
  position: relative;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}
</style>
