<template>
  <div class="pb-10 pa-5">
    <div class="d-flex justify-space-between">
      <p class="text-h6 font-weight-regular d-flex align-center">
        <v-icon class="mr-2">
          mdi-lock
        </v-icon> Hinweis
      </p>
    </div>

    <span class="font-weight text-body-1 ">
      Die Möglichkeit {{ infoText }} zu buchen ist nur für Kunden ohne aktive Kündigung verfügbar.
      Sprechen Sie jetzt mit unserem <a :href="email">Support</a> für weitere Informationen.
    </span>
  </div>
</template>

<script>
import featureNames from '@/lib/featureNames'
import UpsellingType from './enums/UpsellingType'

const INFO = {
  [UpsellingType.GUIDE]: 'einen neuen Ratgeber ',
  [UpsellingType.QUIZZARD]: 'ein zusätzliches Tool ',
  [UpsellingType.CHECKLIST]: 'eine neue Checkliste '
}

export default {
  name: 'Blocked',

  props: {
    type: { type: String, default: UpsellingType.CHECKLIST }
  },

  computed: {
    infoText () {
      return INFO[this.type] ? `${INFO[this.type]}` : INFO[UpsellingType.QUIZZARD]
    },
    email () {
      return `mailto:${this.$features.feature(featureNames.SUPPORT_CONTACT).config.email}`
    }
  }
}
</script>

<style scoped>

</style>
