<template>
  <div>
    <Circles
      class="banner-design-elements"
      width="1000px"
      style="right: -250px; top:-200px; opacity: 0.05"
    />
  </div>
</template>

<script>
import Circles from '@/components/design-elements/circles.vue'

export default {
  components: {
    Circles
  }
}
</script>
