<template>
  <v-container class="layout-container">
    <download-area />
    <overview />
  </v-container>
</template>

<script>
import Overview from '@/modules/landingpages'
import DownloadArea from '@/modules/landingpages/DownloadArea'
import trackingEvents from '@/lib/trackingEvents'

export default {
  components: {
    Overview,
    DownloadArea
  },
  data () {
    return {
      trackingEvents
    }
  }
}
</script>
