<template>
  <ul>
    <li
      v-for="item of items"
      :key="item"
      class="my-1"
    >
      <v-icon
        color="primary"
        class="mr-2"
      >
        mdi-check-circle
      </v-icon>
      <span class="text-body-1 ">{{ item }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
ul {
  list-style-type: none;
  padding-left: 0px;
}
</style>
