<template>
  <UpgradeCard
    v-if="isLight"
    :icon-spacing="120"
    cta="Ja, ich will die Werbepakete!"
    title="Kampagnen: Werbepakete für mehr Akquise"
  >
    <v-col
      lg="10"
      cols="12"
    >
      <div class="text-body-1">
        BOTTIMMO Premium-Kunden können hier aus aktuellen Kampagnen auswählen. Eine Werbekampagne besteht aus
        verschiedenen Werbematerialien zu einem speziellen Thema wie Energiepreise oder Preisveränderungen, aber auch
        jahreszeitspezifisch zu Weihnachten und Ostern.
      </div>
      <div class="my-4 upgrade-list">
        <div class="mb-2 text-body-1 font-weight-bold">
          Eine Kampagnen kann zum Beispiel enthalten:
        </div>
        <UpgradeItemList
          :items="['Flyer','Anzeige','Plakat','Social Media Posts','Kundenanschreiben']"
        />
      </div>
      <div class="mb-1 text-body-1">
        Die Werbemittel werden in Ihren Unternehmensfarben und mit Ihrem Logo erstellt.
      </div>
      <div class="text-body-1">
        Ist in Ihrer Farm noch ein Premium-Paket verfügbar? Das finden wir im persönlichen Gespräch heraus.
      </div>
    </v-col>
  </UpgradeCard>
  <div v-else-if="campaigns && bookedCampaigns">
    <v-row
      v-for="campaign in campaigns"
      :key="campaign.id"
    >
      <v-col>
        <CampaignCard
          v-if="hasReadyMaterials(campaign.id)"
          :campaign="campaign"
          :materials="getMaterials(campaign.id)"
          :landingpages="company.landingpages"
          :country="company.country"
        />

        <UpsellingCard
          v-else
          type="CAMPAIGN"
          :name="campaign.name"
          :slug="campaign.slug"
          :requirements="campaignRequirements(campaign)"
          :submit="bookCampaign"
          :running="isBooked(campaign)"
          :price="price"
          :variant="company.variant"
          :is-booking-blocked="false"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BOOK_CAMPAIGN from './BookCampaign.gql'
import CampaignCard from './CampaignCard.vue'
import UpsellingCard from '../../upselling/UpsellingCard.vue'
import UpgradeCard from '../UpgradeCard.vue'
import UpgradeItemList from '../UpgradeItemList.vue'
import { isLightUser } from '@/lib/productTypes'

export default {
  components: {
    CampaignCard,
    UpsellingCard,
    UpgradeCard,
    UpgradeItemList
  },

  props: {
    getMaterials: {
      type: Function,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    landingpages: {
      type: Array,
      required: true
    },
    landingpageTemplates: {
      type: Array,
      required: true
    },
    price: {
      type: Object,
      default: () => ({})
    },
    isBookingBlocked: {
      type: Boolean,
      required: true
    },
    campaigns: {
      type: Array,
      default: () => {}
    },
    bookedCampaigns: {
      type: Array,
      default: () => {}
    }
  },
  data: () => ({
    fetchCampaignInterval: null,
    fetchCampaignTimeout: null
  }),

  unmounted () {
    if (this.fetchCampaignInterval) {
      clearInterval(this.fetchCampaignInterval)
    }
    if (this.fetchCampaignTimeout) {
      clearTimeout(this.fetchCampaignTimeout)
    }
  },

  computed: {
    isLight () {
      return isLightUser(this.$auth.user)
    },
    activeLandingpages () {
      return this.mapSlugToName(this.landingpages)
    },
    bookableLandingpages () {
      return this.mapSlugToName(this.landingpageTemplates)
    }
  },

  methods: {
    isBooked (campaign) {
      return !!this.bookedCampaigns.find(bookedCampaign => bookedCampaign.slug === campaign.slug)
    },

    hasReadyMaterials (campaignId) {
      const materials = this.getMaterials(campaignId)
      return materials.length > 0 && materials.every(material => material.hasFile)
    },

    campaignRequirements (campaign) {
      // return landingpages which the campaign needs but are not activated
      if (this.$apollo.loading) return []
      const requiredLandingpages = campaign.landingpages
        .map(lp => lp.landingpageSlug)
        .filter(slug => !this.isActive(slug))
        .map(this.getReadableName)
      return this.unique(requiredLandingpages)
    },

    mapSlugToName (lps) {
      return lps.reduce((result, { slug, name }) => ({
        ...result,
        [slug]: name
      }), {})
    },

    unique (items) {
      return Array.from(new Set(items))
    },

    isActive (lpSlug) {
      return this.activeLandingpages[lpSlug]
    },

    getReadableName (lpSlug) {
      return this.bookableLandingpages[lpSlug]
    },

    startFetchCampaignInterval () {
      // for 15 minutes fetch all Campaigns every minute
      const timeout = 1000 * 60 * 15
      this.fetchCampaignInterval = setInterval(() => {
        this.$apollo.queries.campaigns.refetch()
        this.$apollo.queries.bookedCampaigns.refetch()
        this.$apollo.queries.materials.refetch()
      }, 60000)

      this.fetchCampaignTimeout = setTimeout(() => {
        clearInterval(this.fetchCampaignInterval)
      }, timeout)
    },

    async bookCampaign (_, slug) {
      try {
        await this.$apollo.mutate({
          mutation: BOOK_CAMPAIGN,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              slug: slug
            }
          }
        })
        this.startFetchCampaignInterval(slug)
      } catch (e) {
        throw new Error('GraphQL Error: ', e)
      }
    }
  }

}
</script>
