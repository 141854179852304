<template>
  <div class="pa-5">
    <div class="design-wrapper">
      <Leaf
        class="design-elements"
        width="500"
        style="top:-700px; left: -200px; opacity: 0.15"
        fill
      />

      <Leaf
        class="design-elements"
        width="60"
        style="top:50px; left: 10; opacity: 0.8"
        stroke
        :stroke-width="1"
      />

      <Leaf
        class="design-elements"
        width="60"
        style="top:50px; left:20; opacity: 0.3"
        fill
      />
    </div>

    <div
      style="z-index: 2; position: relative"
    >
      <p
        class="text-h6 font-weight-regular"
        v-html="title"
      />

      <div class="justify-end d-flex">
        <div class="pl-16 mr-5 flex-grow-1 hidden-sm-and-down">
          <div class="justify-center d-flex">
            <div>
              <span class="font-weight text-body-1">{{ subTitle }}</span>
              <ul>
                <li
                  v-for="(item, i) in bulletPoints"
                  :key="i"
                >
                  <v-icon
                    color="primary"
                    class="mr-2"
                  >
                    mdi-check-circle
                  </v-icon>
                  <span class="font-weight text-body-1 ">{{ item }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          class="d-flex"
          :class="{'w-full':$vuetify.breakpoint.smAndDown}"
        >
          <div class="w-full flex-column d-flex">
            <div class="w-full mb-4 d-flex justify-space-between align-end">
              <div class="image-wrapper hidden-md-and-up">
                <v-img
                  class="rounded-lg prev-image"
                  :src="imgSrcBySlugAndVariant()"
                  max-width="100"
                  @click="imageDialog = true"
                />
                <v-icon
                  class="image-icon"
                  small
                >
                  mdi-magnify-plus
                </v-icon>
              </div>

              <p
                class="font-weight-regular text-h6 text-md-center ma-0 pa-0"
                :class="{'w-full':$vuetify.breakpoint.mdAndUp}"
              >
                {{ pricing }}
              </p>
            </div>

            <v-btn
              :width="$vuetify.breakpoint.mdAndDown ? '100%': '350px' "
              color="primary"
              @click="submit"
            >
              Jetzt freischalten ...
            </v-btn>
          </div>
        </div>

        <div class="hidden-sm-and-down mt-n12">
          <div class="image-wrapper">
            <v-img
              class="rounded-lg prev-image ml-sm-4"
              :src="imgSrcBySlugAndVariant()"
              max-width="100"
              @click="imageDialog = true"
            />
            <v-icon
              class="image-icon"
              small
            >
              mdi-magnify-plus
            </v-icon>
          </div>
        </div>

        <v-dialog
          v-model="imageDialog"
          max-width="500"
        >
          <v-card
            :style="cssProps"
          >
            <div class="dialog-image-container">
              <v-img
                max-height="75vh"
                :src="imgSrcBySlugAndVariant()"
              />
              <div class="banner text-body-1 text-md-h6">
                Musteransicht
              </div>
              <div class="overlay" />
            </div>

            <v-card-text>
              <v-btn
                class="my-2"
                color="primary"
                width="100%"
                @click="submit"
              >
                Jetzt freischalten ...
              </v-btn>
              <v-btn
                icon
                absolute
                top
                right
                @click="imageDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import UpsellingType from './enums/UpsellingType'
import Leaf from '@/components/design-elements/leaf.vue'

const TITLES = {
  [UpsellingType.GUIDE]: {
    default: 'Dieser Ratgeber kann neue Kontakte bringen.'
  },
  [UpsellingType.CHECKLIST]: {
    default: 'Diese Checkliste kann neue Kontakte bringen.'
  },
  [UpsellingType.CAMPAIGN]: {
    default: 'Diese Kampagne jetzt hinzubuchen und in wenigen Minuten nutzen.',
    sicherheit: 'Die Kampagne passend zum neuen Köder <strong>"Situations-Analyse"</strong>.'
  },
  [UpsellingType.QUIZZARD]: {
    default: 'Dieses Tool jetzt hinzubuchen und in wenigen Minuten nutzen.'
  }
}

const SUBTITLES = {
  [UpsellingType.GUIDE]: {
    default: 'Zur Verwendung als Ausdruck oder auf der Webseite erhalten Sie'
  },
  [UpsellingType.CHECKLIST]: {
    default: 'Zur Verwendung als Ausdruck oder auf der Webseite erhalten Sie'
  },
  [UpsellingType.CAMPAIGN]: {
    default: 'Für Ihre Kampagne erhalten Sie sofort'
  },
  [UpsellingType.QUIZZARD]: {
    default: 'Zur Verwendung auf der Webseite erhalten Sie'
  }
}

const BULLET_POINTS = {
  [UpsellingType.GUIDE]: {
    default: ['hochwertige digitale Materialien', 'in Ihren Farben', 'als Landingpage und Website-Widget']
  },
  [UpsellingType.CHECKLIST]: {
    default: ['hochwertige digitale Materialien', 'in Ihren Farben', 'als Landingpage und Website-Widget']
  },
  [UpsellingType.CAMPAIGN]: {
    default: ['hochwertige Werbemittel', 'individualisiert auf Ihr Maklerbüro', 'direkt zum Download hier in Ihrer Makler-App'],
    sicherheit: ['Flyer, Plakate, Zeitungsanzeige', 'Interview, Newsletter Block, Kundenbriefe', 'Social Media Assets und umfassendes Begleitdokument']
  },
  [UpsellingType.QUIZZARD]: {
    default: ['ein Quiz als Landingpage und Website-Widget', 'in Ihren Farben', 'individualisiert auf Ihr Maklerbüro']
  }
}

const PRICING = {
  [UpsellingType.GUIDE]: {
    default: 'monatlich nur'
  },
  [UpsellingType.CHECKLIST]: {
    default: 'monatlich nur'
  },
  [UpsellingType.CAMPAIGN]: {
    default: 'einmalig nur'
  },
  [UpsellingType.QUIZZARD]: {
    default: 'monatlich nur'
  }
}

const IMAGE_BY_SLUG = {
  [UpsellingType.CHECKLIST]: {
    'aufbereitung-der-immobilie': 'BOTTIMMO-Checkliste-aufbereitung-der-immobilie_screen.jpg',
    energieausweis: 'BOTTIMMO-Checkliste-energieausweis_screen.jpg',
    'unterlagen-fuer-den-verkauf': 'BOTTIMMO-Checkliste-unterlagen-fuer-den-verkauf_screen.jpg',
    'was-gehoert-in-ein-expose': 'BOTTIMMO-Checkliste-was-gehoert-in-ein-expose_screen.jpg'
  },
  [UpsellingType.GUIDE]: {
    'immobilie-geerbt': 'BOTTIMMO-Ratgeber-immobilie-geerbt_screen.jpg',
    'immobilie-in-der-scheidung': 'BOTTIMMO-Ratgeber-immobilie-in-der-scheidung_screen.jpg',
    'immobilien-fakten-und-begriffe': 'BOTTIMMO-Ratgeber-immobilien-fakten-und-begriffe_screen.jpg',
    immobilienfinanzierung: 'BOTTIMMO-Ratgeber-immobilienfinanzierung_screen.jpg',
    leibrente: 'BOTTIMMO-Ratgeber-leibrente_screen.jpg',
    'privater-immobilienverkauf': 'BOTTIMMO-Ratgeber-privater-immobilienverkauf_screen.jpg',
    'richtiger-immobilienpreis': 'BOTTIMMO-Ratgeber-richtiger-immobilienpreis_screen.jpg',
    'sanierung-einer-immobilie': 'BOTTIMMO-Ratgeber-sanierung-einer-immobilie_screen.jpg',
    'wohnen-im-alter': 'BOTTIMMO-Ratgeber-wohnen-im-alter_screen.jpg'
  },
  [UpsellingType.CAMPAIGN]: {
    dschungel: 'Vorschau_Immobiliendschungel.jpg',
    lokalheld: 'Vorschau_Lokalheld.jpg',
    stadtland: 'Vorschau_Stadt-vs-Land.jpg',
    weihnachten: 'Vorschau_Weihnachten.jpg',
    willstdu: 'Vorschau_Willstdu.jpg',
    'immobilienbewertung-januar2022': 'Vorschau_Immobilienbewertung-Januar2022.jpeg',
    sicherheit: 'Vorschau_Sicherheit.jpg',
    grundsteueralarm: 'Vorschau_Grundsteueralarm.jpg',
    leibrente: 'Vorschau_Leibrente.jpg'
  },
  [UpsellingType.QUIZZARD]: {
    sicherheit: 'QUIZZARD_Sicherheit.jpg'
  }
}

export default {
  name: 'Banner',

  components: { Leaf },

  props: {
    type: { type: String, default: UpsellingType.LANDINGPAGE },
    price: { type: String, default: '' },
    submit: { type: Function, default: () => {} },
    slug: { type: String, default: '' },
    variant: { type: String, default: '' }
  },

  data () {
    return {
      imageDialog: false
    }
  },

  computed: {
    title () {
      return TITLES[this.type][this.slug.toLowerCase()] || TITLES[this.type].default
    },
    subTitle () {
      return SUBTITLES[this.type][this.slug.toLowerCase()] || SUBTITLES[this.type].default
    },
    bulletPoints () {
      return BULLET_POINTS[this.type][this.slug.toLowerCase()] || BULLET_POINTS[this.type].default
    },
    pricing () {
      return `${PRICING[this.type][this.slug.toLowerCase()] || PRICING[this.type].default} ${this.price}`
    },
    cssProps () {
      return {
        '--warning-color': this.$vuetify.theme.themes.light.warning
      }
    }
  },

  methods: {
    imgSrcBySlugAndVariant (preview = false) {
      const image = IMAGE_BY_SLUG[this.type][this.slug.toLowerCase()]
      if (!image) {
        // @todo replace with custom static image
        return `https://via.placeholder.com/${preview ? '150x212' : '300x425'}/FFFFFF/000000/?text=${this.slug}`
      }
      return `https://static.bottimmo.show/images/covers/${this.variant.toUpperCase()}/${image}?w=${preview ? '150' : '600'}`
    }
  }
}
</script>

<style scoped>
.design-wrapper {
  position: relative;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}

.background-image{
  position: absolute;
  bottom: 0;
  left: 30px;
  height: 100px;
}

.bg-purple {
    color: #fff;
}

.prev-image {
  cursor: pointer;
}

.image-wrapper {
  position: relative;
}
.image-icon {
  position: absolute;
  bottom: 18px;
  right: 4px;
}

@media (min-width: 600px) {
  .image-icon {
    bottom: 4px;
    right: 4px;
  }

  .banner{
    top:50px;
    left: -70px;
  }
}

.dialog-image-container{
  position:relative;
}

.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(210deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%);
}

.banner {
    position: absolute;
    top:40px;
    left: -90px;
    background-color: var(--warning-color) ;
    color:white;
    text-align:center;
    width:300px;
    height:40px;
    line-height:40px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
</style>
