<template>
  <div class="pb-10 pa-5">
    <div class="d-flex justify-space-between">
      <p class="text-h6 font-weight-regular d-flex align-center">
        <v-icon class="mr-2">
          mdi-information
        </v-icon> Hinweis
      </p>
      <v-btn
        icon
        @click="cancel"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <span
      v-if="hasOpenRequirements"
      class="font-weight text-body-1 "
    >
      Gute Wahl! Diese Kampagne ist ein großer Schritt in die richtige Richtung. Zur Aktivierung benötigen Sie jedoch noch folgende Unterlagen:
      <span
        v-for="(item, i) in requirements"
        :key="i"
      >
        {{ item }}
        {{ i >= requirements.length-1 ? '':',' }}
      </span>
    </span>

    <span
      v-else
      class="font-weight text-body-1 "
    >
      {{ infoText }} <br>
      Der Betrag wird auf Ihrer Rechnung entsprechend ausgewiesen.
      So behalten Sie die volle Kontrolle über Ihre Kosten.
      {{ durationText }}
    </span>

    <div class="justify-end mt-4 align-center flex-column d-flex flex-md-row">
      <span class="mr-md-2 font-weight-regular text-subtitle-1">
        Das habe ich verstanden, jetzt
      </span>
      <v-btn
        color="primary"
        :disabled="hasOpenRequirements"
        @click="submit"
      >
        Zahlungspflichtig bestellen
      </v-btn>
    </div>
  </div>
</template>

<script>
import UpsellingType from './enums/UpsellingType'

const INFO = {
  [UpsellingType.GUIDE]: 'Für die Aktivierung dieser Funktion berechnen wir nur ',
  [UpsellingType.CHECKLIST]: 'Für die Aktivierung dieser Funktion berechnen wir nur ',
  [UpsellingType.QUIZZARD]: 'Für die Aktivierung dieser Funktion berechnen wir nur ',
  [UpsellingType.CAMPAIGN]: 'Für die Aktivierung dieser Kampagne berechnen wir einmalig '
}

export default {
  name: 'Confirmation',

  props: {
    type: { type: String, default: UpsellingType.CHECKLIST },
    price: { type: String, default: '' },
    requirements: { type: Array, default: () => [] },
    cancel: { type: Function, default: () => {} },
    submit: { type: Function, default: () => {} }
  },

  computed: {
    infoText () {
      return `${INFO[this.type]} ${this.price}${this.type !== UpsellingType.CAMPAIGN ? '/Monat' : ''}`
    },
    durationText () {
      return this.type !== UpsellingType.CAMPAIGN ? 'Die Mindestvertragslaufzeit beträgt 2 Monate, höchstens aber bis zum Ablaufdatum Ihres aktuellen Paketes.' : ''
    },
    requiredLandingpages () {
      return this.feature.landingpages.map(landingpage => landingpage.landingpageSlug)
    },
    hasOpenRequirements () {
      return this.requirements.length > 0
    }
  }
}
</script>

<style scoped>

</style>
