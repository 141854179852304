<template>
  <div>
    <v-row
      v-for="lp in landingpages"
      :key="lp.slug"
    >
      <v-col>
        <LandingpageCard
          v-bind="lp"
          :icon="lp.icon"
          has-action
        >
          <template v-slot:content>
            <LandingpageCardContent
              v-bind="lp"
              :landingpage-id="lp.id"
              :conversion-label="conversionLabel(lp.type)"
              :materials-length="isLight ? lightMaterials[lp.slug].length : materialCount(lp.id)"
              has-click-action
              @click="handleClick(lp.type)"
            />
          </template>
        </LandingpageCard>
      </v-col>
    </v-row>

    <!-- Property Tracker -->
    <v-row v-if="permissions && permissions.leadImport.hasPermission">
      <v-col>
        <LandingpageCard
          name="Eigentums-Radar"
          icon="mdi-radar"
          slug="eigentums-radar"
        >
          <template v-slot:content>
            <LandingpageStatisticsCardContent
              :statistics="propertyTrackerStatistics"
            />
          </template>
        </LandingpageCard>
      </v-col>
    </v-row>

    <UpgradeCard
      v-if="isLight"
      cta="Ja, ich will alle Tools!"
      title="Sie wollen mehr kleine Helfer? Mehr Landingpages? Mehr Werbemittel? Bitte schön!"
    >
      <v-col
        lg="10"
        cols="12"
      >
        <div class="text-body-1">
          Für den vollen Akquise-Schub profitieren BOTTIMMO Premium-Kunden von einem
          umfangreichen Programm an Werkzeugen und Landingpages:
        </div>
        <div class="my-4 upgrade-list">
          <UpgradeItemList
            :items="[
              'Immobilienbewertung für Ihre Kunden mit Sofortergebnis',
              'In-App Immobilienbewertung für Sie',
              'Grundstücksbewertung',
              'Leibrenten-Check',
              'Situations-Analyse'
            ]"
          />
        </div>
        <div class="mb-1 text-body-1">
          Zu allen Tools finden Sie zudem eine Auswahl an wirksamen Werbemitteln wie
          Flyern, Plakaten, Videos und Social Media Posts.
        </div>
        <div class="text-body-1">
          Ist in Ihrer Farm noch ein Premium-Paket verfügbar? Das finden wir im persönlichen Gespräch heraus.
        </div>
      </v-col>
    </UpgradeCard>
    <div v-else>
      <v-row
        v-for="lp in bookableLps"
        :key="lp.slug"
      >
        <v-col>
          <UpsellingCard
            :name="lp.name"
            :type="lp.type"
            :slug="lp.slug"
            :is-booking-blocked="isBookingBlocked"
            :submit="bookLandingpage"
            :price="price"
            :variant="company.variant"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PURCHASE_PROPERTIES_STATISTICS from './PurchasePropertiesStatistics.gql'
import PERMISSIONS from '@/queries/Permissions.gql'
import LandingpageCard from '../LandingpageCard'
import { TOOLS } from '@/lib/tools'
import LandingpageCardContent from '../LandingpageCardContent.vue'
import LandingpageStatisticsCardContent from '../statistics/LandinpageStatisticsCardContent.vue'
import { persistFilter, resetAllFilters } from '@/modules/leads/helper/filter'
import materialCount from '@/lib/materialCount'
import UpgradeCard from '../UpgradeCard.vue'
import UpgradeItemList from '../UpgradeItemList.vue'
import { isLightUser } from '@/lib/productTypes'
import lightMaterials from '@/lib/lightMaterials' // temporary solution for light-product, see comment in lightMaterials.js

export default {
  components: {
    LandingpageCard,
    LandingpageCardContent,
    LandingpageStatisticsCardContent,
    UpgradeCard,
    UpgradeItemList
  },

  props: {
    getMaterials: {
      type: Function,
      required: true
    },
    bookLandingpage: {
      type: Function,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    landingpages: {
      type: Array,
      required: true
    },
    bookableLps: {
      type: Array,
      required: true
    },
    isBookingBlocked: {
      type: Boolean,
      required: true
    },
    price: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      lightMaterials
    }
  },

  apollo: {
    purchasePropertiesStatistics: {
      query: PURCHASE_PROPERTIES_STATISTICS
    },
    permissions: {
      query: PERMISSIONS,
      update: data => data
    }
  },

  computed: {
    isLight () {
      return isLightUser(this.$auth.user)
    },
    propertyTrackerStatistics () {
      if (!this.purchasePropertiesStatistics) return
      const preparedCountData = [
        {
          value: this.purchasePropertiesStatistics.invitedLeads,
          label: 'eingeladen',
          icon: 'mdi-email-fast-outline'
        },
        {
          value: this.purchasePropertiesStatistics.hotLeads,
          label: 'aktiviert',
          icon: 'mdi-radar'
        }
      ]
      return preparedCountData
    }
  },

  methods: {
    conversionLabel (type) {
      return TOOLS.find(tool => tool.type === type).action
    },
    handleClick (type) {
      resetAllFilters()

      const tool = TOOLS.find(tool => tool.type === type)
      persistFilter(tool.key, true)

      this.$router.push('/leads?filter')
    },
    materialCount (lpId) {
      const materials = this.getMaterials(lpId)
      return materialCount(materials)
    }
  }
}
</script>
